.section-treasury {
    padding: 0;
    margin-top: 20px;
    margin-bottom: 30px;

    .section-content {
        margin-bottom: 0;
    }

    .default-text:last-child {
        margin: 0;
    }
}


@include media-breakpoint-up(sm) {
    .section-treasury {
        margin-top: 30px;

        .default-text {
            font-size: 1rem;
        }
    }
}

@include media-breakpoint-up(md) {
    .section-treasury {
        margin-top: 40px;
        margin-bottom: 40px;

        .section-title {
            margin-bottom: 25px;
        }
    }
}

@include media-breakpoint-up(xl) {
    .section-treasury {
        margin-top: 50px;
        margin-bottom: 50px;
    }
}
