.section-banner {
    padding: 0;
}

.banner-content {
    position: relative;
    width: 100%;
}

.banner {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #ebebeb;
    overflow: hidden;

    &--height-auto {
        &.banner:after {
            display: none;
        }

        .banner-right {
            height: auto;
            background: linear-gradient(0, rgba(27, 145, 212, 0.7) 0%, rgba(21, 80, 158, 0.7) 100%);
            margin: 0 -15px;
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    &:before {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba($color: #000000, $alpha: 0.3);
        z-index: 2;
    }

    &:after {
        content: '';
        width: 100%;
        height: 75px;
        display: block;
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        @include gradient-opacity;
        z-index: 3;
    }

    &--client {
        &:before {
            display: none;
        }

        picture {
            display: none;
        }
    }

    &--connection:after {
        height: 100%;
    }
}

.banner-bg {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }
}

.banner-action {
    .btn {
        width: 100%;
        max-width: 320px;
    }
}
.banner-webinars {
    .btn {
        max-width: 100%;
    }
}

.banner-action-group {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;

    .btn {
        flex: 1;
        width: auto;

        &:last-child {
            margin-right: 5px;
        }
    }
}

.banner-inner {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 5;
}

.banner-left {
    padding: 15px 0;
    overflow: hidden;

    &--connection {
        display: flex;
        align-items: center;
    }
}

.banner-right {
    height: 75px;
    text-align: center;
    padding: 20px 0;
    z-index: 10;
}

.banner-info {
    display: flex;
    justify-content: space-between;
    color: #fff;
    line-height: 1.3;

    &__title {
        font-size: 1rem;
        font-weight: 700;
        text-align: left;
        flex: 0 0 150px;
        max-width: 150px;
        margin-right: 15px;
    }

    &__text {
        text-align: right;
        max-width: 290px;
        font-size: 1rem;
    }
}

.title-banner {
    color: $white;
    font-size: 1.25rem;
    line-height: 1.2;
    font-weight: 300;

    &--client {
        color: $primary;
    }

    .big {
        font-size: 1.875rem;
        font-weight: 300;
        margin-bottom: 0;
    }

    .str-small {
        display: inline-block;
        margin-top: 10px;
    }
}

.banner-img {
    display: block;
    max-width: 250px;
    height: 50px;
    object-fit: contain;
    object-position: left;
    margin-top: 40px;
    margin-bottom: 15px;
    &-mobile {
        display: none;
    }
}

@include media-breakpoint-up(sm) {
    .banner {
        background-image: none;
        min-height: 370px;

        &--height-auto {
            min-height: auto;

            &.banner:after {
                display: block;
            }

            .banner-right {
                background: none;
                margin: 0;
                padding-left: 0;
                padding-right: 0;
            }
        }

        &:before {
            display: none;
        }

        &:after {
            width: 52%;
            height: 100%;
            top: 0;
            left: auto;
            right: -13%;
            transform: skew(-15deg);
        }

        &--client {
            picture {
                display: initial;
            }
        }

        &--connection {
            min-height: auto;
            padding: 25px 0;

            &:after {
                width: 35%;
            }
        }
    }

    .banner-bg {
        width: 66%;
        transform: skew(-15deg) translateX(28%);

        img {
            transform: skew(15deg) translateX(-19%);
        }
    }

    .banner-left {
        width: 45%;
    }

    .banner-action {
        .btn {
            width: auto;
        }
    }

    .banner-action-group {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;

        .btn {
            width: 192px;
            padding: 9px 20px;

            &:first-child {
                margin-bottom: 10px;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .banner-info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        max-width: 270px;

        &__title {
            font-size: 1.5rem;
            flex: auto;
            text-align: right;
            max-width: none;
            margin-bottom: 10px;
            margin-right: 0;
        }

        &__text {
            max-width: none;
        }
    }

    .banner-inner {
        flex-direction: row;
        padding: 20px 0 55px;
    }

    .slide-left {
        max-width: 52%;
        padding: 0;

        .line-break {
            display: block;
        }
    }

    .banner-right {
        // height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        align-self: flex-end;
        padding: 0;
        padding-right: 10px;
        text-align: right;

        .info-banner {
            font-weight: 600;
            font-size: 2.125rem;
            line-height: 1.3;
            color: $white;
            text-align: right;
            margin-bottom: 25px;
        }
    }

    .title-banner {
        color: $primary;
        font-size: 2.25rem;

        .big {
            font-size: 2.75rem;
        }

        .line-break {
            display: block;
        }

        .str-small {
            margin-top: 30px;
        }
    }

    .banner-img {
        height: auto;
        margin-bottom: 0;
        &-mobile {
            display: block;
        }
        &-logo {
            width: auto;
            height: 65px;
            margin-bottom: 20px;
        }
    }
}

@include media-breakpoint-up(md) {
    .banner {
        background-image: none;

        &:after {
            width: 42%;
        }

        &--connection:after {
            width: 35%;
        }
    }

    .banner-bg {
        width: 54%;
    }

    .slide-left .line-break {
        display: inline-block;
    }

    .banner-left {
        width: 50%;
    }

    .banner-right .info-banner {
        font-size: 2.5rem;
        font-weight: 400;
    }

    .title-banner {
        font-size: 2.5rem;

        .big {
            font-size: 3.125rem;
        }

        .line-break {
            display: inline;
        }
    }

    .banner-action-group .btn {
        width: 240px;
    }

    .banner-img {
        max-width: 300px;
        max-height: 85px;
        margin-top: 60px;
        &-logo {
            display: none;
        }
    }
}

@include media-breakpoint-up(lg) {
    .banner {
        background-image: none;

        &:after {
            width: 45%;
        }

        &--connection:after {
            width: 30%;
        }
    }

    .banner-bg {
        width: 65%;

        &--connection {
            width: 50%;
        }
    }

    .banner-inner {
        padding: 25px 0 55px;
    }

    .banner-left {
        max-width: 42%;
    }

    .banner-info {
        max-width: 290px;
    }

    // .title-banner {
    //     .big {
    //         font-size: 3.125rem;
    //     }

    //     .str-small {
    //         font-size: 2.5rem;
    //     }
    // }
}

@include media-breakpoint-up(xl) {
    .banner {
        min-height: 440px;

        &--height-auto {
            min-height: auto;
        }

        &--connection {
            min-height: auto;
        }
    }

    .banner-bg {
        &--connection {
            width: 45%;
        }
    }
}


// section-banner-quote
.section-banner-quote {
    .banner-content {
        height: auto;
    }

    .banner-inner {
        padding-bottom: 20px;
    }

    .banner {
        position: relative;
        flex-direction: column-reverse;

        &:before {
            display: none;
        }

        .banner-left {
            padding: 35px 0 0;
        }

        .btn {
            position: absolute;
            min-width: 230px;
            bottom: 20px;
            left: 50%;
            padding: 6px 15px;
            transform: translateX(-50%);
            z-index: 5;
        }
    }

    .banner-bg {
        position: relative;
        max-height: 260px;
    }

    .quote-block {
        letter-spacing: -0.2px;
        line-height: 1.2;
    }

    .quote {
        font-style: italic;
        padding-right: 10px;

        &:before,
        &:after {
            color: #d7d7d8;
            font-style: normal;
        }

        &:after {
            right: 5px;
        }
    }

    .quote-author {
        margin: 20px 0 25px 0;
        padding: 0 15px 0 2px;
    }
}

@include media-breakpoint-up(sm) {
    .section-banner-quote {
        padding-bottom: 20px;

        .banner {
            flex-direction: row;

            &:after {
                width: 26%;
            }

            .banner-left {
                padding: 35px 0;
            }
        }

        .banner-bg {
            position: absolute;
            max-height: none;
            transform: skew(-15deg) translateX(19%);

            img {
                transform: skew(15deg) translateX(-19%);
            }
        }

        .section-action {
            margin-top: 30px;
        }

        .quote-block {
            line-height: 1.4;
        }

        .quote {
            padding-right: 0;
        }
    }
}

@include media-breakpoint-up(md) {
    .section-banner-quote {
        .banner:after {
            width: 32%;
        }

        .banner-bg {
            width: 57%;
        }

        .banner-inner {
            padding-top: 35px;
        }

        .banner-left {
            width: 50%;
        }

        .quote-block {
            line-height: 1.45;
        }

        .section-action {
            margin-top: 45px;
        }
    }
}

@include media-breakpoint-up(xl) {
    .section-banner-quote {
        padding-bottom: 50px;

        .quote-block .link-blue {
            font-size: 1rem;
        }
    }
}

.banner-small {
    min-height: 115px;
    &:after {
        display: none;
    }

    &-with-btns {
        &:after {
            display: block;
        }
    }
}

@include media-breakpoint-up(sm) {
    .banner-small {
        &:after {
            display: block;
            width: 35%;
        }

        &-with-btns {
            .banner-inner {
                padding: 45px 0 55px;
            }

            .banner-left {
                padding: 0;
            }

            &:after {
                width: 50%;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .banner-small-with-btns {
        .banner-bg {
            width: 65%;
        }
    }
}
