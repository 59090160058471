.section-other-products {
    padding: 0;
    margin-top: 30px;
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    @include media-breakpoint-up(lg) {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .section-content {
        margin-bottom: 0;
    }

    .products-slider {
        position: relative;
        height: 150px;
        display: flex;
        justify-content: space-between;
        padding: 0 22px;

        &:before,
        &:after {
            content: '';
            height: 100%;
            width: 50px;
            background-color: #fff;
            position: absolute;
            top: 0;
            z-index: 5;
            transform: skew(-15deg);
        }

        &:before {
            left: -10px;
        }

        &:after {
            right: -25px;
        }

        .slick-arrow {
            z-index: 6;
        }
    }

    .product-slide {
        transform: skew(-15deg);
        border-left: 1px solid #e8e8e8;
    }

    .product {
        padding: 0 25px;
        min-height: 145px;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom: none;

        &:hover {
            &:after {
                opacity: 0;
            }
            .product-name-inner {
                border-bottom: 1px solid rgba(21,84,161,.4);
            }
        }

        .product-name-inner {
            border-bottom-color: rgba(0,0,0,0);
            text-decoration: none;
            transition: border-bottom .2s linear,text-decoration .2s linear,-webkit-text-decoration .2s linear;
        }

        .product-icon {

            &:before,
            &:after {
                display: none;
            }
        }

        .product-icon--1 {
            background-image: url(../../../images/svg/bank.svg);
        }

        .product-icon--2 {
            background-image: url(../../../images/svg/post.svg);
        }

        .product-icon--3 {
            background-image: url(../../../images/svg/MIS.svg);
        }

        .product-icon--4 {
            background-image: url(../../../images/svg/inet-bank.svg);
        }

        .product-icon--5 {
            background-image: url(../../../images/svg/Treasury.svg);
        }

        .product-icon--6 {
            background-image: url(../../../images/svg/adm.svg);
        }

        &:hover {
            background: #fff;
        }

        &:focus {
            outline: 0;
        }

        .product-icon {
            margin-bottom: 15px;
        }

        .product-box {
            text-align: center;
            margin-left: 25px;
        }

        .product-name {
            min-height: 50px;
            color: $blue;
            font-size: 0.875rem;
        }

        .product-icon,
        .product-box {
            transform: skew(15deg);
        }
    }
}

@include media-breakpoint-up(sm) {
    .section-other-products {
        .product {
            max-width: 100%;
            border-right: none;

            .product-icon {
                width: 70px;
                height: 70px;
            }

            .product-name {
                font-size: 1rem;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .section-other-products {

        .products-slider .slick-list {
            width: 100%;
            justify-content: space-between;
        }

        .product {
            padding: 10px;

            .product-icon {
                width: 75px;
                height: 75px;
                margin-right: 25px;
            }

            .product-box {
                padding: 0 0 0 15px;
                margin: 0;
            }

            .product-name {
                font-size: 1.125rem;
            }
        }
    }
}
