.section.section-module-functions {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 30px;
    transition-duration: 0.5s;

    @include media-breakpoint-up(sm) {
        margin-top: 35px;
    }
    @include media-breakpoint-up(md) {
        margin-top: 40px;
    }
    @include media-breakpoint-up(lg) {
        margin-top: 50px;
    }
}

.section-content.module-functions {
    margin-bottom: 0;
}

.module-functions {
    display: flex;
    flex-direction: column;
    transition-duration: 0.5s;

    &__item {
        margin-bottom: 25px;
    }

    &__title {
        font-size: 18px;
        font-weight: 700;
        transition-duration: 0.5s;
    }

    &__text {
        font-size: 14px;
        font-weight: 400;
        transition-duration: 0.5s;
    }

    @include media-breakpoint-up(sm) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: -10px;
        margin-right: -10px;

        &__item {
            flex: 0 0 50%;
            margin-bottom: 30px;
            padding-left: 10px;
            padding-right: 10px;

            &:nth-child(1) {
                order: 1
            }

            &:nth-child(2) {
                order: 3
            }

            &:nth-child(3) {
                order: 5
            }

            &:nth-child(4) {
                order: 2
            }

            &:nth-child(5) {
                order: 4
            }

            &:nth-child(6) {
                order: 6
            }
        }

        &__title {
            font-size: 20px;
        }

        &__text {
            margin-top: 13px;
        }
    }
    @include media-breakpoint-up(md) {
        margin-left: -13px;
        margin-right: -13px;

        &__item {
            flex: 0 0 33.333%;
            margin-bottom: 35px;
            padding-left: 13px;
            padding-right: 13px;
        }

        &__title {
            font-size: 22px;
        }

        &__text {
            font-size: 16px;
        }
    }
    @include media-breakpoint-up(lg) {
        margin-left: -16px;
        margin-right: -16px;

        &__item {
            margin-bottom: 40px;
            padding-left: 16px;
            padding-right: 16px;
        }

        &-title {
            font-size: 24px;
        }
    }
}
