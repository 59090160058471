.section-block {
    padding: 0;
    margin-top: 30px;
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    @include media-breakpoint-up(lg) {
        margin-top: 50px;
        margin-bottom: 50px;
    }
}

.diagram {
    position: relative;
    height: 442px;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: -4px;
        width: 35px;
        height: 100%;
        background: linear-gradient(90deg, hsla(0, 0%, 100%, .1), #fff 65%);
        -webkit-filter: blur(2px);
        filter: blur(1px);
        z-index: 3;
    }

    .container {
        padding-right: 0;
        padding-left: 0;
    }

    &__second-layer {
        position: absolute;
        top: 0;
        left: 0;
        width: 75%;
        height: 100%;
        background: linear-gradient(228deg, #1554A1 0%, #15509E 0.01%, #1552A0 22.92%, #1B91D4 100%);
    }

    .diagram__head {
        display: flex;
        height: 100%;
        min-width: 100%;
        overflow-x: auto;
    }

    &__head-items {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 100%;
        color: #ffffff;
        font-size: 16px;
        font-weight: 700;
        line-height: normal;
        max-width: 357px;
        min-width: 200px;
        padding: 65px 10px 48px 15px;

        @include media-breakpoint-up(sm) {
            position: absolute;
            top: 0;
            left: 0;
            width: 285px;
            height: 442px;
            padding-left: 35px;
            z-index: 5;
            background: linear-gradient(228deg, #1554A1 0%, #15509E 0.01%, #1552A0 22.92%, #1B91D4 100%);
        }
        @include media-breakpoint-up(md) {
            width: 357px;
        }
        @include media-breakpoint-up(xl) {
            position: static;
            background: transparent;
        }

        &--item {
            position: relative;
            z-index: 1;
        }
    }

    &__table {
        min-width: 857px;
        min-height: 442px;
        z-index: 2;
        margin-right: 15px;

        @include media-breakpoint-up(sm) {
            margin-left: 285px;
            overflow-x: auto;
        }
        @include media-breakpoint-up(md) {
            margin-left: 357px;
        }
        @include media-breakpoint-up(xl) {
            margin-left: 0;
        }

        &--image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
