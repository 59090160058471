.new-advantages {
    @include media-breakpoint-up(sm) {
        max-height: 485px;
    }

    color: #FFF;
    padding-top: 30px;
    padding-bottom: 115px;
    background-image: url(../../../images/base_content/section-advantages/new-advantages-mobile-custom.jpeg);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
}

.advantages {
    &__title {
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
        transition-duration: 0.5s;

        @include media-breakpoint-up(sm) {
            font-size: 26px;
            text-align: center;
        }
        @include media-breakpoint-up(md) {
            font-size: 30px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 34px;
        }
    }

    &__content {
        padding-top: 22px;
    }

    &__item {
        margin-bottom: 16px;
        @include media-breakpoint-up(sm) {
            margin-bottom: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &-title {
            display: block;
            font-size: 18px;
            font-weight: 700;
            @include media-breakpoint-up(sm) {
                margin-bottom: 5px;
            }
        }

        &-text {
            display: block;
            font-size: 14px;
            font-weight: 400;
        }
    }
}

@include media-breakpoint-up(sm) {
    .new-advantages {
        max-height: 485px;
        padding-bottom: 0;
        background-image: url(../../../images/base_content/section-advantages/new-advantages-middle.jpg);
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 100% 100%;

        @include media-breakpoint-up(md) {
            padding-top: 40px;
        }
        @include media-breakpoint-up(lg) {
                padding-top: 50px;
        }
    }

    .advantages {
        &__content {
            position: relative;
            min-height: 485px;
        }

        &__item {
            &:before {
                content: '';
                position: absolute;
                top: 8px;
                left: 0;
                width: 10px;
                height: 10px;
                border-radius: 50%;
            }

            &:after {
                content: '';
                position: absolute;
                top: 13px;
                left: 4px;
                width: 2px;
            }

            &:nth-child(1) {
                position: absolute;
                top: 22px;
                left: -5px;
                padding-left: 22px;
                max-width: 231px;

                .advantages__item-title {
                    transition-duration: 0.5s;
                    @include media-breakpoint-up(md) {
                        font-size: 20px;
                    }
                    @include media-breakpoint-up(lg) {
                        font-size: 22px;
                    }
                }

                .advantages__item-text {
                    transition-duration: 0.5s;
                    @include media-breakpoint-up(md) {
                        font-size: 16px;
                    }
                }

                &:before {
                    background-color: #E68352;
                    filter: blur(1px);
                }

                &:after {
                    background-color: #E68352;
                    height: 290px;
                    filter: blur(1px);
                    transition-duration: 0.5s;
                    @include media-breakpoint-up(lg) {
                        height: 284px;
                    }
                }
            }

            &:nth-child(2) {
                position: absolute;
                top: 40%;
                left: 6%;
                padding-left: 22px;
                max-width: 174px;
                transition-duration: 0.5s;
                @include media-breakpoint-up(md) {
                    max-width: 258px;
                    top: 35%;
                    left: 8%;
                }
                @include media-breakpoint-up(lg) {
                    max-width: 258px;
                    top: 35%;
                    left: 11%;
                }

                .advantages__item-title {
                    transition-duration: 0.5s;
                    font-size: 20px;
                    @include media-breakpoint-up(md) {
                        font-size: 22px;
                    }
                    @include media-breakpoint-up(lg) {
                        font-size: 24px;
                    }
                }

                .advantages__item-text {
                    transition-duration: 0.5s;
                    @include media-breakpoint-up(md) {
                        font-size: 16px;
                    }
                }

                &:before {
                    background-color: #25C3EC;
                }

                &:after {
                    background-color: #25C3EC;
                    height: 164px;
                    transition-duration: 0.5s;
                    @include media-breakpoint-up(md) {
                        height: 123px;
                    }
                    @include media-breakpoint-up(lg) {
                        height: 170px;
                    }
                }
            }

            &:nth-child(3) {
                position: absolute;
                top: 21%;
                left: 19%;
                padding-left: 22px;
                max-width: 205px;
                transition-duration: 0.5s;
                @include media-breakpoint-up(md) {
                    top: 21%;
                    left: 26%;
                    max-width: 250px;
                }
                @include media-breakpoint-up(lg) {
                    top: 20%;
                    left: 28%;
                    max-width: 300px;
                }

                .advantages__item-title {
                    transition-duration: 0.5s;
                    font-size: 16px;
                    @include media-breakpoint-up(lg) {
                        font-size: 18px;
                    }
                }

                .advantages__item-text {
                    transition-duration: 0.5s;
                    @include media-breakpoint-up(lg) {
                        font-size: 16px;
                    }
                }

                &:before {
                    background-color: #0189F7;
                    mix-blend-mode: color-dodge;
                    filter: blur(2px);
                }

                &:after {
                    background-color: #0189F7;
                    height: 194px;
                    mix-blend-mode: color-dodge;
                    filter: blur(2px);
                    @include media-breakpoint-up(md) {
                        height: 180px;
                    }
                }
            }

            &:nth-child(4) {
                position: absolute;
                top: 13%;
                left: 53%;
                padding-left: 22px;
                max-width: 205px;
                transition-duration: 0.5s;
                @include media-breakpoint-up(lg) {
                    left: 57%;
                }

                .advantages__item-title {
                    font-size: 20px;
                    transition-duration: 0.5s;
                    @include media-breakpoint-up(md) {
                        font-size: 22px;
                    }
                    @include media-breakpoint-up(lg) {
                        font-size: 24px;
                    }
                }

                .advantages__item-text {
                    transition-duration: 0.5s;
                    @include media-breakpoint-up(lg) {
                        font-size: 16px;
                    }
                }

                &:before {
                    background-color: #FF5455;
                }

                &:after {
                    background-color: #FF5455;
                    height: 315px;
                    transition-duration: 0.5s;
                    @include media-breakpoint-up(md) {
                        height: 303px;
                    }
                    @include media-breakpoint-up(lg) {
                        height: 290px;
                    }
                }
            }

            &:nth-child(5) {
                position: absolute;
                top: 30%;
                right: -16px;
                padding-left: 22px;
                max-width: 131px;
                transition-duration: 0.5s;
                @include media-breakpoint-up(md) {
                    top: 20%;
                    right: -13px;
                    max-width: 201px;
                }
                @include media-breakpoint-up(lg) {
                    top: 10%;
                    right: -10px;
                    max-width: 241px;
                }

                .advantages__item-title {
                    font-size: 16px;
                    transition-duration: 0.5s;
                    @include media-breakpoint-up(md) {
                        font-size: 18px;
                    }
                }

                .advantages__item-text {
                    transition-duration: 0.5s;
                    @include media-breakpoint-up(md) {
                        font-size: 16px;
                    }
                }

                &:before {
                    background-color: #00D0F1;
                    mix-blend-mode: color-dodge;
                    filter: blur(2px);
                }

                &:after {
                    background-color: #00D0F1;
                    height: 175px;
                    mix-blend-mode: color-dodge;
                    filter: blur(2px);
                    transition-duration: 0.5s;
                    @include media-breakpoint-up(md) {
                        height: 221px;
                    }
                }
            }

            &:nth-child(6) {
                position: absolute;
                top: 44%;
                left: 61%;
                padding-left: 22px;
                max-width: 250px;
                transition-duration: 0.5s;
                @include media-breakpoint-up(md) {
                    top: 39%;
                    left: 69%;
                    max-width: 241px;
                }
                @include media-breakpoint-up(lg) {
                    top: 34%;
                    left: 75%;
                }

                .advantages__item-title {
                    transition-duration: 0.5s;
                    @include media-breakpoint-up(md) {
                        font-size: 20px;

                    }
                    @include media-breakpoint-up(lg) {
                        font-size: 22px;
                    }
                }

                .advantages__item-text {
                    transition-duration: 0.5s;
                    @include media-breakpoint-up(lg) {
                        font-size: 16px;
                    }
                }

                &:before {
                    background-color: #FEC16B;
                    filter: blur(1px);
                }

                &:after {
                    background-color: #FEC16B;
                    height: 150px;
                    filter: blur(1px);
                    transition-duration: 0.5s;
                    @include media-breakpoint-up(md) {
                        height: 75px;
                    }
                    @include media-breakpoint-up(lg) {
                        height: 120px;
                    }
                }
            }
        }
    }
}
