.section-product-advantage {
    .section-content {
        margin-bottom: 0;

        + .section-action {
            margin-top: 30px;
        }
    }

    &-indent {
        padding-bottom: 0;
    }
}

.product-advantage {
    display: flex;
    flex-direction: column;
    margin: 0 -15px 20px;

    .animated {
        animation-duration: 1.5s;
    }

    .description {
        flex: 1;
        padding: 0 15px;
    }

    .description-block {
        display: flex;
        flex-direction: column;
        margin-bottom: 22px;

        .title {
            font-size: 1.125rem;
            font-weight: 700;
            margin-bottom: 12px;
            line-height: 1.2;
            letter-spacing: -0.2px;
        }

        div {
            animation-name: fadeInUp;
        }
    }

    .image-advantage {
        flex: 1;
        width: 100%;
        height: 160px;
        animation-name: fadeInUp;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }

    &-single {
        margin-bottom: 0;
        .description {
            position: relative;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            padding: 35px 15px;

            &-block {
                margin-bottom: 0;
            }

            .text {
                position: relative;
                margin-bottom: 0;
                z-index: 2;
                animation: none;
            }

            &:after {
                content: '';
                width: 100%;
                height: 100%;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(#fff, 0.75);
                z-index: 1;
            }
        }

        .image-advantage  {
            padding: 0 15px;

            iframe {
                height: 250px;
            }
            img {
                display: none;
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .section-product-advantage {
        padding-bottom: 10px;

        &-indent {
            padding-top: 0;
        }
    }

    .product-advantage {
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 25px;

        &:nth-child(odd) {
            flex-direction: row;

            .description {
                margin-right: 15px;
            }

            .description-block {
                div {
                    animation-name: fadeInLeft;
                }
            }

            .image-advantage {
                animation-name: fadeInRight;
            }
        }

        &:nth-child(even) {
            flex-direction: row-reverse;

            .description {
                margin-left: 15px;
            }

            .description-block {
                div {
                    animation-name: fadeInRight;
                }
            }

            .image-advantage {
                animation-name: fadeInLeft;
            }
        }

        .description {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-basis: 50%;
            padding: 0;
        }

        .description-block {
            .title {
                font-size: 1.25rem;
            }
        }

        .image-advantage {
            height: 245px;
            flex-basis: 50%;
        }

        &-single {
            &:nth-child(1) {
                flex-direction: row-reverse;

                .description .text {
                    animation-name: fadeInRight;
                }

                .image-advantage {
                    display: block;
                    animation-name: fadeInLeft;
                }
            }

            .description {
                background: none !important;
                padding: 0;
                margin-left: 0;
                margin-right: 0;
                padding-left: 35px;

                &-block {
                    margin-bottom: 7px;
                }
            }

            .image-advantage  {
                iframe {
                    height: 100%;
                }
                img {
                    display: block;
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .section-product-advantage {
        padding-bottom: 25px;

        .section-content + .section-action {
            margin-top: 45px;
        }

        .section-title {
            margin-bottom: 30px;
        }
    }

    .product-advantage {
        margin-bottom: 15px;

        &:nth-child(odd) {
            .description {
                margin-right: 35px;
            }
        }

        &:nth-child(even) {
            .description {
                margin-left: 35px;
            }
        }

        .description-block {
            .title {
                font-size: 1.5rem;
            }

            .default-text { //замена класса .text
                letter-spacing: -0.2px;
            }
        }

        .image-advantage {
            height: 305px;
        }

        &-single {
            margin-bottom: 30px;
            &:nth-child(odd) .description {
                margin-right: 0;
            }

            .description {
                padding-left: 40px;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .section-product-advantage {
        padding-bottom: 40px;
        &-indent {
            padding-bottom: 45px;
        }
    }

    .product-advantage {
        &:nth-child(odd) {
            .description {
                margin-right: 75px;
            }
        }

        &:nth-child(even) {
            .description {
                margin-left: 75px;
            }
        }

        .description-block {
            .title {
                margin-bottom: 20px;
            }

            .default-text {
                letter-spacing: 0;
                padding-right: 50px;
            }
        }

        &-single {
            .description {
                &:nth-child(odd) {
                    margin-left: 75px;
                    margin-right: 0;
                    padding-left: 0;
                }
            }
        }
    }
}
