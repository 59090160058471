.single-news {
    max-height: 175px;
    border-bottom: 1px solid #c9cbcc;
    padding: 15px 0;

    .new-item__content {
        padding: 0;
    }

    &__head {
        position: relative;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: wrap;
        font-size: 0.75rem;

        overflow: hidden;
        z-index: 0;

        &.tags-block {
            display: block;
            max-height: 90px;
        }

        &--shadow {
            &::after {
                content: '';

                position: absolute;
                top: -10px;
                right: -5px;

                width: 30px;
                height: 130%;

                background: linear-gradient(to right, rgba(255, 255, 255, 0.1), #ebebeb 65%);
                filter: blur(2px);

                z-index: 3;
            }
        }
    }

    &__tag {
        margin-right: 10px;
    }

    &__labels,
    &__tags {
        display: flex;
    }

    &__text-block {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        padding-bottom: 1px;
    }

    &__text {
        display: inline;
        color: $primary;
        font-size: 1rem;
        line-height: 1.5;

        &:hover {
            color: $primary;
        }
    }

    &__footer {
        font-size: 0.75rem;
        margin-top: 10px;
        cursor: default;
    }
}
