.section-news {
    padding: 0;

    .section-action {
        text-align: center;
        margin-top: 15px;
        margin-bottom: 15px;

        @include media-breakpoint-up(sm) {
            margin-top: 20px;
        }
        @include media-breakpoint-up(md) {
            margin-top: 25px;
        }
        @include media-breakpoint-up(lg) {
            margin-top: 35px;
        }
    }
}

.filters-news {
    margin-top: 30px;
    margin-bottom: 40px;
}

.new-item,
.search-block {
    margin-bottom: 30px;

    &__image {
        position: relative;
        display: block;
        width: 100%;
        height: 265px;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;

        &:after {
            content: '';
            position: absolute;
            display: block;
            width: 100%;
            top: 0;
            bottom: 0;
            background: rgba(0, 0, 0, .2);
            opacity: 0;
            transition: opacity .3s linear;
        }

        &:hover:after {
            opacity: 1;
        }
    }

    &__content {
        position: relative;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    &__data {
        position: relative;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: wrap;

        margin-bottom: 15px;

        overflow: hidden;

        z-index: 0;

        &.tags-block {
            display: block;
            max-height: 90px;
        }

        &--shadow {
            &::after {
                content: '';

                position: absolute;
                top: -10px;
                right: -10px;

                width: 30px;
                height: 100%;

                background: linear-gradient(to right, rgba(255, 255, 255, 0.1), #fff 65%);
                filter: blur(2px);

                z-index: 3;
            }
        }
    }

    &__labels,
    &__tags {
        display: flex;
    }

    &__title {
        font-size: 1.125rem;
        margin-bottom: 10px;
    }

    &__desc {
        margin-bottom: 10px;
        font-size: 1rem;
    }

    &__place {
        font-size: 0.875rem;
    }
}

.new-main {
    &__image {
        &:after {
            opacity: 1;
        }

        &:hover:after {
            opacity: 0;
        }
    }
}

@include media-breakpoint-up(sm) {
    .filters-news {
        &>label {
            display: none;
        }
    }

    .filter-news {
        display: flex;
        align-items: center;
        flex: 1 0 48%;
        max-width: 48%;

        label {
            margin-bottom: 0;
        }

        &:not(:last-child) {
            margin-bottom: 0;
        }

        &:first-child {
            margin-right: 15px;
        }

        .select2.select2-container.select2-container--default {
            max-width: none !important;
        }
    }

    .new-item {
        &__inner {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }

    .new-main {
        min-height: 550px;

        &__content {
            padding: 0 25px 30px 25px;
        }

        &__inner {
            position: relative;
            justify-content: flex-end;
        }

        &__image {
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }

        a.new-main__tag,
        &__title a,
        &__desc,
        &__place {
            margin-top: 10px;
            color: #fff;
        }

        a.new-main__tag {
            margin-top: 0;
        }

        &__title a,
        a.new-main__tag {
            @include underline-white;
        }

        a.new-main__tag {
            text-decoration: none;
            transition: border-bottom 0.2s linear;

            &:hover {
                border-bottom-color: transparent;
                text-decoration: none;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .filter-news {
        &:first-child label {
            flex-basis: 200px;
        }

        &:last-child label {
            flex-basis: 115px;
        }
    }
}

.client-news {
    background-color: #ebebeb;
    padding-top: 30px;
    padding-bottom: 15px;

    @include media-breakpoint-up(sm) {
        padding-top: 35px;
        padding-bottom: 20px;
    }
    @include media-breakpoint-up(md) {
        padding-top: 40px;
        padding-bottom: 25px;
    }
    @include media-breakpoint-up(lg) {
        padding-top: 50px;
        padding-bottom: 35px;
    }

    .section-content {
        margin-bottom: 0;
    }

    .news-img__link {
        display: block;
        height: 265px;
        width: 100%;
    }

    .news-img {
        display: block;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .new-item__data--shadow {
        &::after {
            content: '';
            position: absolute;
            top: -2px;
            right: -3px;
            width: 30px;
            height: 100%;
            background: linear-gradient(to right, rgba(255, 255, 255, 0.1), #ebebeb 65%);
            filter: blur(1px);
            z-index: 3;
        }
    }
}
