@include media-breakpoint-up(sm) {
    .top-banner {
        transition-duration: 0.5s;
        padding: 16px 0;

        & .title-banner {
            margin-top: 10px;
        }

        & .title-banner .str-small {
            margin-top: 0;
        }

        & .title-banner .big,
        & .title-banner .str-small {
            transition-duration: 0.5s;
            font-size: 30px;
            font-weight: 300;
        }
    }
}

@include media-breakpoint-up(md) {
    .top-banner {
        transition-duration: 0.5s;
        padding: 26px 0;

        & .title-banner {
            margin-top: 10px;
        }

        & .title-banner .str-small {
            margin-top: 0;
        }

        & .title-banner .big,
        & .title-banner .str-small {
            font-size: 40px;
        }
    }
}

@include media-breakpoint-up(lg) {
    .top-banner {
        transition-duration: 0.5s;
        padding: 36px 0;

        & .banner-left {
            max-width: 46%;
        }

        & .title-banner {
            margin-top: 10px;
        }

        & .title-banner .str-small {
            margin-top: 0;
        }

        & .title-banner .big,
        & .title-banner .str-small {
            font-size: 50px;
        }
    }
}
