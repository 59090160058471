.section-result {
    padding: 0;
    margin-top: 30px;
    margin-bottom: 30px;

    &__content {
        margin-bottom: 0;
    }
}

.results-list {
    display: flex;
    flex-direction: column;
}

.results {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;

    &:last-child {
        margin-bottom: 0;
    }

    &__head {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
    }

    &__icon {
        display: block;
        width: 60px;
        height: 60px;
        flex-shrink: 0;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center top;
        margin-right: 20px;

        &--speed {
            background-image: url(../../../images/inet-bank/01-speed.svg);
        }

        &--income {
            background-image: url(../../../images/inet-bank/02-income.svg);
        }

        &--reputation {
            background-image: url(../../../images/inet-bank/03-reputation.svg);
        }

        &--process {
            background-image: url(../../../images/svg/treasury/001-process.svg);
        }

        &--growth {
            background-image: url(../../../images/svg/treasury/002-growth.svg);
        }

        &--comfort {
            background-image: url(../../../images/svg/treasury/003-armchair.svg);
        }

        &--ecosystem {
            background-image: url(../../../images/svg/post/001-icon-2942621.svg);
        }

        &--scale {
            background-image: url(../../../images/svg/post/002-scale.svg);
        }

        &--future {
            background-image: url(../../../images/svg/post/003-city.svg);
        }

        &--full {
            background-image: url(../../../images/svg/abs/002-management.svg);
        }

        &--optimization {
            background-image: url(../../../images/svg/abs/003-analysis.svg);
        }

        &--extension {
            background-image: url(../../../images/svg/abs/004-website.svg);
        }

        &--effective {
            background-image: url(../../../images/product-first/result-01.svg);
        }

        &--optimization-prod-first {
            background-image: url(../../../images/product-first/result-02.svg);
        }

        &--functionality {
            background-image: url(../../../images/product-first/result-03.svg);
        }
    }

    &__title {
        margin-bottom: 0;
    }
}

.connection {
    display: flex;
    justify-content: center;
    min-height: 195px;
    color: #fff;
}

@include media-breakpoint-up(sm) {
    .connection {
        justify-content: flex-start;
        color: $primary;
        padding: 20px 0;

        h3 {
            margin-bottom: 0;
        }
    }

    .results__icon {
        width: 70px;
        height: 70px;
    }
}

@include media-breakpoint-up(md) {
    .section-result {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    
    .results-list {
        flex-direction: row;

        &__item {
            flex: 31%;
            max-width: 31%;

            & + & {
                margin-left: 20px;
            }
        }
    }

    .results {
        margin-bottom: 0;

        &__head {
            min-height: 96px;
        }

        &__icon {
            width: 75px;
            height: 75px;
            margin-right: 25px;
        }
    }
}

@include media-breakpoint-up(lg) {
    .section-result {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .results {
        &__head {
            min-height: 115px;
        }
    }

    .connection {
        margin-left: 85px;
    }
}

@include media-breakpoint-up(xl) {
    .results {
        &__head {
            min-height: auto;
            margin-bottom: 30px;
        }
    }
}
