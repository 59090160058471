.section-projects {
    padding: 0;
    margin-top: 30px;
    margin-bottom: 30px;

    .section-content {
        margin-bottom: 0;
    }
}

.projects-slider {
    padding-bottom: 15px;

    .slick-dots {
        flex-direction: row;
        bottom: 0;
        right: 50%;
        transform: translate(50%, 0);

        li {
            button:before {
                color: #aebbcc;
            }

            &.slick-active button:before {
                color: #499bff;
            }
        }
    }

    &--single {
        .slick-dots {
            display: none;
        }
    }
}

.project {
    display: flex;
    flex-direction: column;

    &-head {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;

        .img-bank {
            width: auto;
            height: 50px;

            img {
                height: 100%;
                width: auto;
                max-width: 280px;
                object-fit: contain;
            }
        }
    }

    &-title {
        color: $blue;
        font-size: 1.125rem;
        line-height: 1.2;
        margin-top: 10px;
        flex: 1;
    }

    &-body {
        display: flex;
        flex-direction: column;

    }

    &-item {
        display: flex;
        margin-bottom: 15px;

        .icon {
            display: block;
            width: 35px;
            height: 30px;
            flex-shrink: 0;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 35px 30px;
            margin-right: 15px;

            &-done {
                background-image: url(../../../images/svg/note-case.svg);
            }

            &-time {
                background-image: url(../../../images/svg/001-appointment.svg);
            }

            &-result {
                background-image: url(../../../images/svg/handshake.svg);
            }
        }
    }

    .block {
        display: flex;
        flex-direction: column;
    }

    &-subtitle {
        font-weight: 700;
        margin-bottom: 5px;
    }

    &-text {
        padding-right: 30px;
        line-height: 1.2;

        &.list {
            margin-bottom: 0;
        }
    }
}

@include media-breakpoint-up(sm) {
    .project {
        padding: 0 50px;

        &-head {
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: flex-start;
        }

        &-title {
            padding-right: 15px;
            letter-spacing: 0.8px;
            margin-top: 0;
        }

        &-body {
            flex-direction: row;
        }

        &-item {
            flex: 0 0 48%;

            &+& {
                margin-left: 35px;
            }

            .icon {
                width: 48px;
                height: 48px;
                background-size: 48px 48px;
            }
        }

        &-subtitle {
            font-size: 1rem;
            line-height: 1.2;
            margin-bottom: 0;
        }

        &-text {
            padding-right: 0;
            line-height: 1.4;
        }
    }

    .projects-slider {
        .slick-prev, .slick-next {
            top: 50%;
            transform: translateY(-50%);
        }

        .slick-prev {
            transform: rotate(180deg) translateY(50%);
        }

        &--single {
            .slick-track {
                float: left;
            }

            .project {
                padding-left: 0;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .section-projects {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .projects-slider {
        position: relative;
        padding: 0 35px;

        &:before,
        &:after {
            content: '';
            display: block;
            width: 5px;
            height: 100%;
            background-color: #fff;
            position: absolute;
            top: 0;
            z-index: 5;
        }
        &:before {
            left: 33px;
        }
        &:after {
            right: 33px;
        }

        .project-slide {
            border-right: 1px solid #dedede;
        }

        .project-slide:not(:nth-child(1)),
        .project-slide:not(:nth-child(2)) {
            opacity: 0;
        }

        .slick-track {
            display: flex;
        }

        &.slick-initialized {
            .project-slide {
                opacity: 1;
                height: auto;
            }
        }

        &--client {
            .project-slide {
                border-right: none;
            }

            .project-body {
                flex-direction: row;
            }
        }

        &--single {
            padding: 0;

            &:before,
            &:after {
                display: none;
            }
        }
    }

    .project {
        padding: 0 20px;

        &-head {
            min-height: 96px;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 25px;

            .img-bank {
                flex: 0 0 35%;
                max-width: 35%;
                width: auto;
                height: 100%;

                img {
                    width: 100%;
                    max-height: 95px;
                }
            }
        }

        &-title {
            flex: 0 0 65%;
            max-width: 65%;
            font-size: 1.5rem;
            letter-spacing: -0.2px;
            flex-shrink: 3;
        }

        &-body {
            flex-direction: column;
        }

        &-item {
            margin-bottom: 25px;

            &+& {
                margin-left: 0;
            }
        }

        &-subtitle {
            font-size: 1.125rem;
            margin-bottom: 5px;
        }

        &-text {
            font-size: 1rem;
        }
    }
}

@include media-breakpoint-up(lg) {
    .section-projects {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .project-item {
        font-size: 1rem;
    }
}
