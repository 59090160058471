.offer-webinar-theme {
    padding: 0;
    &__bg {
        height: 374px;
        background-image: url(../../../images/base_content/webinars-page/banner-offer-theme-mobile.jpeg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @include media-breakpoint-up(sm) {
            margin-top: 35px;
            margin-bottom: 35px;
            height: 190px;
            background-image: url(../../../images/base_content/webinars-page/banner-offer-theme-desktop.jpeg);
        }
        @include media-breakpoint-up(md) {
            margin-top: 40px;
            margin-bottom: 40px;
            height: 230px;
            background-position: top;
        }
        @include media-breakpoint-up(lg) {
            margin-top: 50px;
            margin-bottom: 50px;
            height: 260px;
        }
    }

    &__text {
        padding-top: 24px;
        margin-bottom: 20px;
        color: #ffffff;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;

        @include media-breakpoint-up(sm) {
            max-width: 50%;
            margin-bottom: 14px;
        }
        @include media-breakpoint-up(md) {
            font-size: 16px;
        }
        @include media-breakpoint-up(lg) {
            max-width: 40%;
            font-size: 18px;
        }
    }

    .btn {
        min-width: 100%;
        padding: 10px;

        @include media-breakpoint-up(sm) {
            min-width: 290px;
        }
    }
}
