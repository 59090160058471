.big-font {
    font-size: 16px;
    font-weight: 700;

    @include media-breakpoint-up(md) {
        font-size: 18px;
    }
}

.section-timetable {
    padding: 0;
    margin-top: 30px;
    margin-bottom: 30px;

    @include media-breakpoint-up(sm) {
        margin-top: 35px;
        margin-bottom: 35px;
    }
    @include media-breakpoint-up(md) {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    @include media-breakpoint-up(lg) {
        margin-top: 50px;
        margin-bottom: 50px;
    }
}

.timetable {
    width: 100%;
    position: relative;
    border-image: linear-gradient(39deg, #DFDFE5, #DFDFE5);
    border-image-slice: 1;
    opacity: 1;
    border: 1px solid #DFDFE5;
    margin-bottom: 15px;

    &:last-child {
        margin-bottom: 0;
    }

    &:after {
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        border: 3px solid #15509e;
        border-image: linear-gradient(228deg, #1554A1 0%, #15509E 0.01%, #1552A0 22.92%, #1B91D4 100%);
        border-image-slice: 1;
        opacity: 0;
        z-index: 1;
        transition: opacity 0.3s;
    }

    &:hover {
        cursor: pointer;

        .timetable-header__title {
            text-decoration-color: rgba(0, 0, 0, 0);
        }

        &:after {
            opacity: 1;
            z-index: 1;
        }
    }

    &.open:after {
        opacity: 1;
    }

    @include media-breakpoint-up(md) {
        flex-wrap: wrap;
    }
    @include media-breakpoint-up(lg) {
        margin-bottom: 20px;
    }
}

.timetable-date {
    position: relative;
    width: 94px;
    height: 85px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(228deg, #1554A1 0%, #15509e 0.01%, #1552a0 22.92%, #1b91d4 100%);
    color: #FFF;
    padding-top: 15px;
    padding-right: 8px;
    margin-top: 0;
    margin-left: 0;

    @include media-breakpoint-up(md) {
        order: 1;
        width: 133px;
        height: 120px;
        padding-top: 24px;
        padding-right: 15px;
        margin-bottom: 24px;
    }
    @include media-breakpoint-up(xl) {
        width: 149px;
        height: 135px;
        padding-top: 29px;
        padding-right: 20px;
        margin-bottom: 0;
    }

    &:after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        border-top: 84px solid transparent;
        border-right: 22px solid #ffffff;
        border-bottom: 0 solid transparent;

        @include media-breakpoint-up(md) {
            border-top: 119px solid transparent;
            border-right: 32px solid #ffffff;
        }
        @include media-breakpoint-up(xl) {
            border-top: 134px solid transparent;
            border-right: 35px solid #ffffff;
        }
    }

    &__month {
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
            font-size: 16px;
        }
    }

    &__number {
        font-size: 36px;
        font-weight: 700;
        margin-top: -10px;

        @include media-breakpoint-up(md) {
            font-size: 50px;
        }
    }
}

.timetable-header {
    margin: 17px 20px 13px;

    @include media-breakpoint-up(md) {
        order: 2;
        flex: 1 1 43%;
        margin-top: 20px;
        margin-bottom: 0;
        margin-left: 6px;

        @include media-breakpoint-up(xl) {
            margin-left: 32px;
        }
    }

    &__topic {
        @include media-breakpoint-up(md) {
            font-size: 16px;
        }
    }

    &__title {
        cursor: pointer;
        display: inline-block;
        color: #1554A1;
        text-decoration-line: underline;
        margin-bottom: 5px;
        transition-duration: 0.2s;
    }
}

.timetable-time {
    display: inline-block;
    position: relative;
    padding: 5px 10px 5px 36px;
    border-radius: 5px;
    background: #F5F5F5;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
        margin: 6px 20px 0 0;
        font-size: 16px;
    }

    &:before {
        content: '';
        display: block;
        width: 30px;
        height: 100%;
        background-image: url(../../../images/base_content/svg/time.svg);
        background-repeat: no-repeat;
        position: absolute;
        top: 8px;
        left: 12px;
    }
}

.timetable-button__wrapper {
    position: relative;
    z-index: 3;
    margin: 0 20px 24px;

    @include media-breakpoint-up(md) {
        order: 3;
        margin: 23px;
    }
    @include media-breakpoint-up(xl) {
        margin-top: 42px;
        margin-right: 36px;
    }

    .timetable-button {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 293px;
        width: 100%;
        min-height: 36px;
        padding: 0 10px;

        @include media-breakpoint-up(md) {
            min-width: 235px;
            min-height: 52px;
        }
        @include media-breakpoint-up(xl) {
            min-width: 280px;
        }
    }
}

.timetable-description__wrapper {
    transition-duration: 0.5s;
    height: 0;
    overflow: hidden;
    min-width: 100%;

    @include media-breakpoint-up(md) {
        order: 4;
    }
}

.timetable-description {
    margin-right: 20px;
    margin-left: 20px;

    &__title {
        margin-bottom: 5px;
    }

    li {
        position: relative;
        padding-left: 13px;
        margin-bottom: 5px;

        &:last-child {
            margin-bottom: 0;
        }

        &:before {
            content: '';
            position: absolute;
            top: 9px;
            left: 0;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: linear-gradient(228deg, #1554A1 0%, #15509E 0.01%, #1552A0 22.92%, #1B91D4 100%)
        }
    }

    @include media-breakpoint-up(md) {
        display: flex;
        margin-left: 28px;
        margin-bottom: 30px;
    }
    @include media-breakpoint-up(xl) {
        margin-left: 182px;
        margin-right: 36px;
    }
}

.timetable-program {
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
        flex: 0 1 68%;
        margin-right: 20px;
        margin-bottom: 0;

        &__items {
            font-size: 16px;
        }
    }
    @include media-breakpoint-up(xl) {
        flex: 0 1 65%;
        margin-right: 30px;
    }

}

.timetable-speakers {
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
        flex: 0 1 32%;
        margin-bottom: 0;
    }
    @include media-breakpoint-up(xl) {
        flex: 0 1 35%;
    }
}

.timetable-speaker {
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }

    &__name,
    &__position {
        display: block;
    }

    @include media-breakpoint-up(md) {
        font-size: 16px;
    }
}
