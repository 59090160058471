.section-functionality {
    background-color: #ebebeb;
    width: 100%;
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;

    @include media-breakpoint-up(sm) {
        min-height: 320px;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    @include media-breakpoint-up(md) {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    @include media-breakpoint-up(lg) {
        min-height: 480px;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    &:after {
        content: "";
        display: block;
        width: 40%;
        min-height: 260px;
        background: linear-gradient(228deg, #1554A1 0%, #15509E 0.01%, #1552A0 22.92%, #1B91D4 100%);
        transform: skewX(-15deg);
        position: absolute;
        top: 0;
        left: 76%;

        @include media-breakpoint-up(sm) {
            left: 72%;
            min-height: 265px;
        }
        @include media-breakpoint-up(lg) {
            min-height: 100%;
        }
    }
}

.functionality {
    max-width: 1324px;
    margin: 0 auto;

    &__title {
        z-index: 1;
        position: relative;

        @include media-breakpoint-up(sm) {
            max-width: 350px;
        }
        @include media-breakpoint-up(md) {
            max-width: 500px;
        }
        @include media-breakpoint-up(lg) {
            max-width: 100%;
        }
    }

    &__image {
        position: relative;
        float: right;
        z-index: 1;
        margin-top: 10px;
        width: 100%;
        max-width: 290px;
        object-fit: contain;
        transition-duration: 0.3s;

        @include media-breakpoint-up(sm) {
            margin-top: -45px;
            margin-right: -25px;
            max-width: 360px;
        }
        @include media-breakpoint-up(md) {
            margin-top: -40px;
            margin-right: -25px;
            max-width: 430px;
        }
        @include media-breakpoint-up(lg) {
            max-width: 515px;
            margin-top: 40px;
        }
    }
}

.blue-bullet-list {
    margin-top: 15px;
    padding-top: 220px;

    @include media-breakpoint-up(sm) {
        padding-top: 0;
    }
    @include media-breakpoint-up(sm) {
        margin-top: 30px;
        font-size: 16px;
    }
    @include media-breakpoint-up(lg) {
        margin-top: 35px;
        columns: 2 auto;
    }

    &__item {
        position: relative;
        padding-left: 23px;
        margin-right: 10px;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }

        &:before {
            content: '';
            position: absolute;
            display: block;
            top: 8px;
            left: 0;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: linear-gradient(39deg, #1b91d4 0%, #15509e 100%);
        }
    }
}
