.diagram-wrapper {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: -4px;
        width: 35px;
        height: 100%;
        background: linear-gradient(90deg, hsla(0, 0%, 100%, .1), #fff 65%);
        -webkit-filter: blur(2px);
        filter: blur(1px);
        z-index: 3;
    }
}

.diagram-image {
    max-height: 575px;
    width: 100%;
    overflow-x: auto;

    .container {
        padding-right: 0;
    }

    &__image {
        width: 1254px;
        height: 100%;
    }
}
