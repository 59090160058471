.section-key-advantages {
    background-color: #ebebeb;
    width: 100%;
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;

    @include media-breakpoint-up(sm) {
        min-height: 300px;
        padding-top: 35px;
        padding-bottom: 35px;
    }
    @include media-breakpoint-up(md) {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    @include media-breakpoint-up(lg) {
        min-height: 380px;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    &:after {
        content: "";
        display: block;
        width: 40%;
        min-height: 266px;
        background: linear-gradient(228deg, #1554A1 0%, #15509E 0.01%, #1552A0 22.92%, #1B91D4 100%);
        transform: skewX(-15deg);
        position: absolute;
        top: 0;
        left: 75%;
        transition-duration: 0.5s;

        @include media-breakpoint-up(sm) {
            min-height: 255px;
            left: 71%;
        }
        @include media-breakpoint-up(md) {
            left: 78%;
        }
        @include media-breakpoint-up(lg) {
            min-height: 100%;
            left: 71%;
        }
    }
}

.key-advantages {
    max-width: 1324px;
    margin: 0 auto;
    position: relative;
    transition-duration: 0.5s;

    &__title {
        position: relative;
        z-index: 4;
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
        max-width: 260px;
        transition-duration: 0.5s;
        @include media-breakpoint-up(sm) {
            max-width: 340px;
            font-size: 26px;
        }
        @include media-breakpoint-up(md) {
            max-width: 400px;
            font-size: 30px;
        }
        @include media-breakpoint-up(lg) {
            max-width: 650px;
            font-size: 34px;
        }
    }

    &__items {
        margin-top: 222px;
        z-index: 2;
        display: flex;
        flex-direction: column;
        transition-duration: 0.5s;

        @include media-breakpoint-up(sm) {
            margin-top: 20px;
            font-size: 14px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 16px;
            margin-top: 36px;
        }

        & li {
            position: relative;
            margin-bottom: 10px;
            padding-left: 19px;
            @include media-breakpoint-up(sm) {
                max-width: 50%;
                margin-bottom: 13px;
            }
            @include media-breakpoint-up(md) {
                max-width: 60%;
            }
            @include media-breakpoint-up(lg) {
                max-width: 57%;
            }
            @include media-breakpoint-up(xl) {
                max-width: 64%;
            }

            &:before {
                content: '';
                display: inline-block;
                position: absolute;
                top: 8px;
                left: 0;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: linear-gradient(39deg, #1b91d4 0%, #15509e 100%);
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__image {
        display: inline-block;
        position: absolute;
        z-index: 3;
        top: 68px;
        right: 19px;
        width: 100%;
        max-width: 290px;
        object-fit: contain;
        transition-duration: 0.5s;

        @include media-breakpoint-up(sm) {
            top: 13px;
            right: -37px;
            max-width: 370px;
        }
        @include media-breakpoint-up(lg) {
            top: 7px;
            right: -40px;
            max-width: 515px;
        }
    }
}
