.section-new-mechanism {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 30px;

    @include media-breakpoint-up(sm) {
        margin-bottom: 35px;
    }
    @include media-breakpoint-up(md) {
        margin-bottom: 40px;
    }
    @include media-breakpoint-up(xl) {
        margin-top: 50px;
        margin-bottom: 45px;
    }
}

.new-mechanism {
    position: relative;

    @include media-breakpoint-up(xl) {
        min-height: 424px;
        display: flex;
    }

    &__blue-arrow--mobile {
        position: relative;
        min-width: 100%;
        margin-left: -35px;
        margin-right: -35px;
        margin-bottom: 5px;
        background: linear-gradient(228deg, #1554a1 0%, #15509e 0.01%, #1552a0 22.92%, #1b91d4 100%);
        transition-duration: 0.5s;

        &:before {
            content: '';
            position: absolute;
            width: 50%;
            left: 0;
            bottom: -1px;
            border-left: 0 solid #ffffff;
            border-right: 380px solid transparent;
            border-bottom: 50px solid #ffffff;

            @include media-breakpoint-up(sm) {
                border-right: 500px solid transparent;

            }
            @include media-breakpoint-up(md) {
                border-right: 600px solid transparent;
            }
            @include media-breakpoint-up(lg) {
                border-right: 676px solid transparent;
            }
        }

        &:after {
            content: '';
            position: absolute;
            width: 50%;
            right: 0;
            bottom: -1px;
            border-left: 380px solid transparent;
            border-right: 0 solid #ffffff;
            border-bottom: 50px solid #ffffff;

            @include media-breakpoint-up(sm) {
                border-left: 500px solid transparent;
            }
            @include media-breakpoint-up(md) {
                border-left: 600px solid transparent;
            }
            @include media-breakpoint-up(lg) {
                border-left: 676px solid transparent;
            }
        }
    }

    &__blue-arrow--desktop {
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        background: linear-gradient(228deg, #1554a1 0%, #15509e 0.01%, #1552a0 22.92%, #1b91d4 100%);
        transition-duration: 0.5s;

        &:before {
            content: '';
            position: absolute;
            width: 0;
            right: -1px;
            height: 50px;
            top: -1px;
            bottom: -1px;
            border-top: 330px solid #ffffff;
            border-left: 50px solid transparent;
            border-bottom: 0 solid #ffffff;
        }

        &:after {
            content: '';
            position: absolute;
            width: 0;
            height: 50%;
            right: -1px;
            bottom: -1px;
            border-bottom: 330px solid #ffffff;
            border-left: 50px solid transparent;
            border-top: 0 solid #ffffff;
        }
    }

    &__content {
        @include media-breakpoint-up(xl) {
            display: flex;
            height: 100%;
        }
    }

    &__blue-arrow--text {
        z-index: 1;
        color: #ffffff;
        flex: 0 0 50%;
        height: 100%;
        text-align: center;
        padding: 10px 30px 50px;
        transition-duration: 0.5s;

        @include media-breakpoint-up(sm) {
            padding: 30px 30px 60px;
        }
        @include media-breakpoint-up(xl) {
            padding: 0 30px 0 0;
            flex-direction: column;
            justify-content: center;
            text-align: left;
        }
    }

    &__list {
        flex: 0 0 50%;

        @include media-breakpoint-up(md) {
            padding-top: 20px;
        }
        @include media-breakpoint-up(xl) {
            padding-left: 30px;
            padding-bottom: 24px;
        }
    }

    &__title {
        text-align: center;
        margin-bottom: 0;
    }

    &__items {
        margin-top: 20px;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(sm) {
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: 0;
            margin-left: -5px;
            margin-right: -5px;
        }
        @include media-breakpoint-up(xl) {
            margin-left: -17px;
            margin-right: -17px;
        }

        &-item {
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 30px;
            position: relative;
            z-index: 3;
            flex: 1 0 60px;
            &:last-child {
                margin-bottom: 0;
            }

            @include media-breakpoint-up(sm) {
                margin-bottom: 0;
                margin-top: 40px;
                flex: 1 0 50%;
                padding: 0 5px;
            }
            @include media-breakpoint-up(md) {
                flex-wrap: nowrap;
                flex: 1 0 25%;
            }
            @include media-breakpoint-up(xl) {
                flex-wrap: wrap;
                flex: 1 0 50%;
                padding: 0 17px;
            }

            &:before {
                position: absolute;
                font-family: "Akrobat-Black", sans-serif;
                z-index: 1;
                opacity: 0.25;
                top: -36px;
                left: 5px;
                width: 80px;
                height: 100px;
                font-size: 80px;
                font-weight: 700;
                color: #1b91d4;

                @include media-breakpoint-up(md) {
                    font-size: 100px;
                }
            }

            &:nth-child(1):before {
                content: '01';
            }

            &:nth-child(2):before {
                content: '02';
            }

            &:nth-child(3):before {
                content: '03';
            }

            &:nth-child(4):before {
                content: '04';
            }
        }
    }
}
