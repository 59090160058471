.section-advantages-new-mechanism {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 30px;

    @include media-breakpoint-up(sm) {
        margin-top: 35px;
    }
    @include media-breakpoint-up(md) {
        margin-top: 40px;
    }
    @include media-breakpoint-up(xl) {
        margin-top: 45px;
    }
}

.results {
    &__icon {
        &--work-in-one-window {
            background-image: url(../../../images/svg/post/010-interface.svg);
        }

        &--easy-setup {
            background-image: url(../../../images/svg/module-page/001-maintenance.svg);
        }

        &--analysis-of-calculations {
            background-image: url(../../../images/svg/module-page/laptop-1.svg);
        }

        &--reporting {
            background-image: url(../../../images/svg/module-page/002-checklist.svg);
        }
    }
}

.advantages-list {
    transition-duration: 0.5s;

    @include media-breakpoint-up(sm) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 35px;
        margin-left: -10px;
        margin-right: -10px;
    }
    @include media-breakpoint-up(md) {
        margin-top: 0;
        margin-left: -15px;
        margin-right: -15px;
    }
    @include media-breakpoint-up(lg) {
        margin-top: 0;
        margin-left: -20px;
        margin-right: -20px;
    }
}

.advantages-item {
    flex: 1 0 50%;
    margin-bottom: 20px;

    @include media-breakpoint-up(sm) {
        padding-left: 10px;
        padding-right: 10px;
    }
    @include media-breakpoint-up(md) {
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 25px;
    }
    @include media-breakpoint-up(lg) {
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 30px;
    }
    @include media-breakpoint-up(xl) {
        margin-bottom: 40px;
    }
}

.advantages {
    margin-top: 0;

    & li {
        max-width: 100%;
    }
}
