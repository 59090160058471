.moduls-list {
    margin: 0 -15px;

    .product {

        @include media-breakpoint-up(sm) {
            border-right: 1px solid #e8e8ec;
            border-bottom: 1px solid #e8e8ec;
        }

        &:hover {
            cursor: pointer;

            .product-name {
                color: #FFFFFF;
            }
        }
    }

    .module-list__btn {
        width: 100%;
        border: none;
        outline: none;
        display: flex;
        align-items: center;
        list-style: none;
        z-index: 2;
        background-color: transparent;
        color: #424852;

        @include media-breakpoint-up(sm) {
            flex-direction: column;
            justify-content: center;
        }
    }

    .product-icon {
        margin-top: 0;
    }

    .product-name {
        margin-bottom: 0;
        text-align: left;
    }
}

.modul-inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.modul-box {
    display: flex;
    align-items: center;

    .product-name {
        margin-bottom: 0;
    }
}

.modul-hide {
    display: none;
    flex-direction: column;
    padding: 15px 0;

    .modul-text {
        padding-bottom: 15px;
    }

    .btn {
        width: 100%;
    }
}

.section-moduls {
    padding-top: 30px;
    @include media-breakpoint-up(sm) {
        padding-top: 40px;
    }
    @include media-breakpoint-up(md) {
        padding-top: 50px;
    }
    .modul {
        padding: 20px 15px;
        height: 111px;
        cursor: pointer;

        &:hover {
            text-decoration: none;
            color: $primary;
            background-position: 0 0;

            &:after {
                opacity: 0;
            }

            .product-icon-before {
                opacity: 1;
            }

            .product-icon-after {
                opacity: 0;
            }
        }

        &.desc,
        &.desc:hover {
            height: 111px;
            color: $white;
            z-index: 500;

            .modul-inner {
                width: 100%;
                height: auto;
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                padding: 20px 15px;
                z-index: 499;
                @include gradient;
            }

            .modul-hide {
                display: flex;
            }

            .product-more {
                display: none;
            }

            .product-icon-before {
                opacity: 0;
            }

            .product-icon-after {
                opacity: 1;
            }
        }
    }

    .product-more {
        background-color: #ececed;

        &:before,
        &:after {
            background-color: #ececed;
        }
    }
}

@include media-breakpoint-up(sm) {
    .moduls-list {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin: 0;

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background-color: #fff;
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            margin-bottom: 0;
        }

        .product {
            flex: 0 0 25%;
            max-width: 25%;
        }

        .product-name {
            font-size: 1.125rem;
            text-align: center;
        }
    }

    .modul-box {
        flex-direction: column;

        .product-name {
            text-align: center;
            padding: 0 10px;
        }
    }

    .modul-hide {
        .btn {
            width: auto;
            padding: 6px 5px;
            margin: 0 12px;
        }
    }

    .section-moduls {
        .section-content {
            padding-top: 25px;
        }

        .modul {
            height: 210px;
            flex: 0 0 33.33%;
            border-bottom: 1px solid #e8e8ec;

            &:hover {
                height: 210px;
                z-index: 500;
                text-decoration: none;
                color: $white;
                background-position: 0 0;

                &:after {
                    opacity: 1;
                }

                .product-icon-before {
                    opacity: 0;
                }

                .product-icon-after {
                    opacity: 1;
                }

                .modul-inner {
                    width: 100%;
                    height: auto;
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    padding: 20px 15px;
                    z-index: 499;
                    @include gradient;
                }

                .modul-hide {
                    display: flex;
                }

                .product-more {
                    display: none;
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .modul-hide .modul-text {
        font-size: 1rem;
    }
    .module-1 {
        order: 7;
    }
    .module-2 {
        order: 8;
    }
    .module-3 {
        order: 1;
    }
    .module-4 {
        order: 2;
    }
    .module-5 {
        order: 3;
    }
    .module-6 {
        order: 4;
    }
    .module-7 {
        order: 5;
    }
    .module-8 {
        order: 6;
    }
}

@include media-breakpoint-up(xl) {
    .modul-hide {
        .btn {
            padding: 6px 30px;
        }
    }

    .section-moduls {
        .section-content {
            padding-top: 45px;
        }

        .modul {
            flex: 0 0 25%;

            &:nth-child(n) {
                border-right: 1px solid #e8e8ec;
            }
        }
    }
}
